@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 700;
    src: url("./fonts/PorscheNextWCy-Bold.woff2") format("woff2"),
        url("./fonts/PorscheNextWCy-Bold.woff") format("woff");
    unicode-range: U+0400-04FF;
    font-display: swap;
}
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/PorscheNextWCy-Regular.woff2") format("woff2"),
        url("./fonts/PorscheNextWCy-Regular.woff") format("woff");
    unicode-range: U+0400-04FF;
    font-display: swap;
}
/*
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 600;
    src: url("./fonts/PorscheNextWCy-SemiBold.woff2") format("woff2"),
        url("./fonts/PorscheNextWCy-SemiBold.woff") format("woff");
    unicode-range: U+0400-04FF;
    font-display: swap;
}
*/
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 100;
    src: url("./fonts/PorscheNextWCy-Thin.woff2") format("woff2"),
        url("./fonts/PorscheNextWCy-Thin.woff") format("woff");
    unicode-range: U+0400-04FF;
    font-display: swap;
}
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 700;
    src: url("./fonts/PorscheNextWGr-Bold.woff2") format("woff2"),
        url("./fonts/PorscheNextWGr-Bold.woff") format("woff");
    unicode-range: U+0370-03FF;
    font-display: swap;
}
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/PorscheNextWGr-Regular.woff2") format("woff2"),
        url("./fonts/PorscheNextWGr-Regular.woff") format("woff");
    unicode-range: U+0370-03FF;
    font-display: swap;
}
/*
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 600;
    src: url("./fonts/PorscheNextWGr-SemiBold.woff2") format("woff2"),
        url("./fonts/PorscheNextWGr-SemiBold.woff") format("woff");
    unicode-range: U+0370-03FF;
    font-display: swap;
}
*/
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 100;
    src: url("./fonts/PorscheNextWGr-Thin.woff2") format("woff2"),
        url("./fonts/PorscheNextWGr-Thin.woff") format("woff");
    unicode-range: U+0370-03FF;
    font-display: swap;
}
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 700;
    src: url("./fonts/PorscheNextWLa-Bold.woff2") format("woff2"),
        url("./fonts/PorscheNextWLa-Bold.woff") format("woff");
    unicode-range: U+0020-007F, U+0080-00FF, U+0100-017F, U+0180-024F, U+0250-02AF, U+02B0-02FF, U+0300-036F, U+0E00-0E7F, U+1E00-1EFF, U+2000-206F, U+2070-209F, U+20A0-20CF, U+2100-214F, U+2150-218F, U+2190-21FF, U+2200-22FF, U+25A0-25FF,
        U+2600-26FF, U+FB00-FB4F, U+FE70-FEFF;
    font-display: swap;
}
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/PorscheNextWLa-Regular.woff2") format("woff2"),
        url("./fonts/PorscheNextWLa-Regular.woff") format("woff");
    unicode-range: U+0020-007F, U+0080-00FF, U+0100-017F, U+0180-024F, U+0250-02AF, U+02B0-02FF, U+0300-036F, U+0E00-0E7F, U+1E00-1EFF, U+2000-206F, U+2070-209F, U+20A0-20CF, U+2100-214F, U+2150-218F, U+2190-21FF, U+2200-22FF, U+25A0-25FF,
        U+2600-26FF, U+FB00-FB4F, U+FE70-FEFF;
    font-display: swap;
}
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 600;
    src: url("./fonts/PorscheNextWLa-SemiBold.woff2") format("woff2"),
        url("./fonts/PorscheNextWLa-SemiBold.woff") format("woff");
    unicode-range: U+0020-007F, U+0080-00FF, U+0100-017F, U+0180-024F, U+0250-02AF, U+02B0-02FF, U+0300-036F, U+0E00-0E7F, U+1E00-1EFF, U+2000-206F, U+2070-209F, U+20A0-20CF, U+2100-214F, U+2150-218F, U+2190-21FF, U+2200-22FF, U+25A0-25FF,
        U+2600-26FF, U+FB00-FB4F, U+FE70-FEFF;
    font-display: swap;
}
@font-face {
    font-family: Porsche Next;
    font-style: normal;
    font-weight: 100;
    src: url("./fonts/PorscheNextWLa-Thin.woff2") format("woff2"),
        url("./fonts/PorscheNextWLa-Thin.woff") format("woff");
    unicode-range: U+0020-007F, U+0080-00FF, U+0100-017F, U+0180-024F, U+0250-02AF, U+02B0-02FF, U+0300-036F, U+0E00-0E7F, U+1E00-1EFF, U+2000-206F, U+2070-209F, U+20A0-20CF, U+2100-214F, U+2150-218F, U+2190-21FF, U+2200-22FF, U+25A0-25FF,
        U+2600-26FF, U+FB00-FB4F, U+FE70-FEFF;
    font-display: swap;
}
